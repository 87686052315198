import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { globalColors } from './globalColorVariables';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // general css rules
    mainSectionContainer: {
      padding: '1rem 0',
      margin: '1rem 0',
      '& a': {
        textDecoration: 'none'
      }
    },
    mainSectionTitle: {
      fontWeight: 600,
      marginBottom: '1.5rem',
      fontSize: '1.5em',
      lineHeight: '1.42'
    },
    formControl: {
      marginBottom: '1rem'
    },
    selectOutline: {
      paddingRight: '4.5em !important'
    },
    actionItem: {
      display: 'flex',
      justifyContent: 'end',
      height: '25px',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    actionItemText: {
      fontWeight: 500,
      color: globalColors.primaryColor,
      paddingRight: '0.5em',
      letterSpacing: '0.8px',
      fontSize: '0.875rem'
    },
    clearAllIcon: {
      zIndex: 1,
      position: 'relative',
      justifyContent: 'right',
      top: '1.8em',
      right: '1.5em'
    },
    filterTitleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    selectedMenu: {
      backgroundColor: 'transparent !important'
    },
    menuItem: {
      whiteSpace: 'normal',
      wordWrap: 'break-word'
    },
    clearAllText: {
      cursor: 'pointer',
      fontWeight: 500,
      letterSpacing: '0.8px',
      fontSize: '0.875rem',
      lineHeight: '16px'
    },
    sectionContainerColumns: {
      padding: '2rem 0',
      display: 'flex'
    },
    containerColumn: {
      padding: '15px'
    },
    sectionHeader: {
      color: '#5182BB',
      padding: '0.5rem 1rem'
    },
    sectionBodyText: {
      color: '#5182BB',
      margin: '1em 0'
    },
    // Template Section Fields
    inputLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '80%',
      height: '100%',
      zIndex: 0,
      display: 'flex',
      justifyContent: 'flex-start',
      fontSize: '0.85rem'
    },
    multiSelectChips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    multiSelectChip: {
      margin: 4
    },
    multiSelectButton: {
      margin: 4
    },
    asterisk: {
      color: 'rgba(229, 77, 57, 1)',
      marginRight: '1px'
    },
    fieldTitle: {
      fontSize: '0.85rem',
      marginBottom: '0.5rem',
      textAlign: 'left'
    },
    // Match [0, 900px)
    [theme.breakpoints.down('md')]: {
      categoryContainer: {
        '& p': {
          fontSize: '0.875em'
        }
      },
      mainSectionTitle: {
        textAlign: 'center',
        fontWeight: 500,
        marginBottom: '1rem',
        fontSize: '1.25em',
        lineHeight: '1.2'
      },
      sectionContainerColumns: {
        flexDirection: 'column'
      },
      sectionHeader: {
        fontSize: '1.25em'
      }
    },
    // Match [0, 600px)
    [theme.breakpoints.down('sm')]: {
      actionItem: {
        minHeight: '20px'
      },
      filterTitleOnMobile: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      filterAppBar: {
        bottom: 0,
        top: 'auto',
        backgroundColor: '#f8f8f8'
      },
      filterToolBar: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '1rem'
      },
      categoryContainer: {
        '& p': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }
      }
    }
  })
);

export default useStyles;
