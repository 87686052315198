import * as React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Database, NovadeLiteTemplateInput } from 'typings';
import { setGTMEvent } from '../utils/setGAEvent';

const UPSERT_TEMPLATE_DATE = gql`
  mutation upsertTemplateData($template: TemplatePortalInput!) {
    savePortalTemplateToWorkspace(template: $template)
  }
`;

const useUpsertTemplate = (
  endpoint: string,
  targetWorkspace: Database | undefined,
  templateDetails: NovadeLiteTemplateInput,
  afterUpsertCallback: (arg0: boolean) => void
) => {
  const [upsertTemplateData, { loading, error }] = useMutation<
    { savePortalTemplateToWorkspace: any },
    { template: NovadeLiteTemplateInput }
  >(UPSERT_TEMPLATE_DATE, {
    variables: {
      template: templateDetails
    },
    context: {
      uri: `${endpoint}/graphql`,
      headers: {
        Authorization: targetWorkspace ? `Bearer ${targetWorkspace.token}` : ''
      }
    },
    onCompleted({ savePortalTemplateToWorkspace }) {
      if (savePortalTemplateToWorkspace) {
        setGTMEvent('Save template to Novade Lite workspace', 'save_To_Novade_Lite', templateDetails.name);
        afterUpsertCallback(true);
      }
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors || networkError) {
        afterUpsertCallback(false);
      }
    }
  });

  const upsertTemplate = React.useCallback(() => {
    upsertTemplateData();
  }, [upsertTemplateData]);

  return { upsertTemplate, loading, error };
};

export default useUpsertTemplate;
