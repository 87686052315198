import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { graphql } from 'gatsby';
import React from 'react';
import useUpsertTemplate from '../hooks/useUpsertTemplate';
import LoginDialog from '../components/LoginDialog';
import ActionButtonsWeb from '../components/Template/ActionButtonsWeb';
import { Languages, defaultLocation } from '../utils/getAllSupportedLanguages';
import MobileFormContainer from '../components/MobileFormContainer';
import PardotEmailDialog from '../components/PardotEmailDialog';
import Seo from '../components/Seo';
import TemplateSeoContent from '../components/Template/TemplateSeoContent';
import TemplateInfoSection from '../components/Template/TemplateInfoSection';
import WorkspaceDialog from '../components/WorkspaceDialog';
import { AuthUserContext } from '../contexts/AuthUserContext';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { NovadeLiteUrlContext } from '../contexts/NovadeLiteUrlContext';
import {
  AuthUserContextType,
  LocationLanguageContextType,
  LogInResponse,
  NovadeLiteUrlContextType,
  NovadeLiteUser,
  StrapiTemplate,
  StrapiTemplatePageQueryProps,
  NovadeLiteTemplateInput,
  Database
} from '../typings';
import { exportToPDF } from '../utils/exportPDF';
import { getAttachmentData, getEmailValues, sendEmail, setEmailValues } from '../utils/sendStrapiEmail';
import { setGTMEvent } from '../utils/setGAEvent';
import { getSortedTemplate, getTemplateValues, updateTemplateCountryCode } from '../utils/templateValues';
import { matchesMobile } from '../utils/mediaQuery';
import { getTotalScoresBySectionMap } from '../utils/previewScoreUtils';
import ActionButtonsMobile from '../components/Template/ActionButtonsMobile';
import TemplateScoreProvider from '../contexts/TemplateScoreContext';
import TemplateSection from '../components/Template/TemplateSection';

interface TemplateProps {
  data: {
    strapiTemplate: StrapiTemplate;
    allStrapiTemplatePage: {
      nodes: StrapiTemplatePageQueryProps[];
    };
  };
}

export const query = graphql`
  query TemplateQuery($id: Int!) {
    strapiTemplate(strapiId: { eq: $id }) {
      id
      published_at
      strapiId
      Template {
        name
        templateGroup {
          name
        }
        startStepID
        templateSections {
          name
          rank
          sectionFields {
            name
            rank
            extra {
              formula
              galleryAllowed
              textValidation
              usedForScore
              score
              source
              filters {
                sameCompanyAsCreator
                sameProjectAsForm
              }
            }
            isMandatory
            fieldInputType
            dataMappingKeyID
            linkedWorkflowStepIDs
            showInPdfExport
            galleryAllowed
            textValidation
            selectOptions {
              color
              name
              rank
              score
            }
            subFields {
              fieldInputType
              name
              rank
              selectOptions {
                color
                name
                rank
              }
              extra {
                calculation
                formula
                galleryAllowed
                textValidation
                source
              }
            }
          }
        }
        workflowActions {
          fromStepID
          toStepID
        }
        workflowSteps {
          color
          id
          name
          phase
          x
          y
          dataMappingKeyID
          options {
            note {
              defaultValue
            }
            requiresSignature
            requiresExplanation
          }
        }
        tags {
          color
          name
        }
      }
      Title
      Description
      Keywords
      Author
      SeoContent
      countries {
        Localizations {
          locale
          localeName
          displayName
        }
        name
      }
    }
    allStrapiTemplatePage {
      nodes {
        PublishedDateLabel
        AuthorLabel
        SaveToNovadeLiteLabel
        locale
        WebPreviewLabel
        MobilePreviewLabel
        DownloadPDFLabel
        SuccessMessage
        ErrorMessage
        DownloadLabel
        WorkspaceLabel
        WorkspaceButton
        FormFieldsSection
        WorkflowSection
        Action
        ActionTooltip
        Closing
        ClosingTooltip
        Preparation
        PreparationTooltip
        TagsLabel
        ActionPrefix
      }
    }
  }
`;

const TemplatePage: React.FC<TemplateProps> = ({ data }) => {
  const template = data.strapiTemplate;
  const templateDetails: NovadeLiteTemplateInput = getSortedTemplate({ ...template.Template, ...{ id: template.id } });
  const templateLanguage = template.template_language || Languages.English;
  const authUser = React.useContext<AuthUserContextType>(AuthUserContext);
  const contextUrl = React.useContext<NovadeLiteUrlContextType>(NovadeLiteUrlContext);
  const databasesMap = authUser ? Object.values(authUser.databasesMap)[0] : {};
  const workspaces = databasesMap ? Object.values(databasesMap) : [];
  const [openWorkspace, setOpenWorkspace] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState<string | undefined>('');
  const [targetWorkspace, setTargetWorkspace] = React.useState<Database | undefined>(workspaces.length ? workspaces[0] : undefined);
  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language = locationLanguage ? locationLanguage.language : Languages.English;
  const country = locationLanguage?.country ?? defaultLocation;

  const templatePageContent = data.allStrapiTemplatePage.nodes.find((x: any) => x.locale === language);
  // Get the total scores
  const templateMaxScoreMap = React.useMemo(() => getTotalScoresBySectionMap(templateDetails), [template?.id]);

  // Use useEffect to update targetWorkspace whenever workspaces change
  React.useEffect(() => {
    const newWorkspace = workspaces.length ? workspaces[0] : undefined;
    setTargetWorkspace(newWorkspace);
  }, [workspaces.length]);

  const openWorkspaceDialog = () => {
    setOpenWorkspace(true);
  };

  const exportPDF = (userData: NovadeLiteUser) => {
    const templateValues = getTemplateValues();
    updateTemplateCountryCode(country.locationCode);
    const pdfEndpoint = `${contextUrl.novadeLiteUrl}/portal-template-pdf`;
    exportToPDF(pdfEndpoint, templateValues).then((fileData) => {
      const attachmentData = getAttachmentData(fileData.fileContent, fileData.fileName, fileData.fileType);
      setEmailValues(userData, language, template, attachmentData!);
      const emailMsg = getEmailValues();
      sendEmail(emailMsg);
    });
    setGTMEvent('Download Template as PDF', 'download_PDF', templateValues.name);
  };

  const handleSnackbarOpen = (status: boolean) => {
    const message = status ? templatePageContent?.SuccessMessage : templatePageContent?.ErrorMessage;
    setSnackBarMsg(message);
    setOpenSnackbar(true);
  };

  const { upsertTemplate, loading } = useUpsertTemplate(contextUrl.novadeLiteUrl, targetWorkspace, templateDetails, handleSnackbarOpen);

  const handleEmailDialogClose = (isSuccessfulSubmit: boolean | undefined, inputEmail: string | undefined) => {
    setOpenEmail(false);
    if (isSuccessfulSubmit) {
      const inputUserData: NovadeLiteUser = {
        email: inputEmail!,
        displayName: '' // guest
      };
      exportPDF(inputUserData);
    }
  };
  const handleSnackbarClose = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const openLoginDialog = () => {
    setOpenLogin(true);
  };

  const handleLoginDialogClose = (value: LogInResponse | undefined) => {
    if (value) {
      authUser?.updateUser({
        email: value.email,
        displayName: value.userDisplayName
      });
      authUser?.updateDatabasesMap(value.databases);
    }
    setOpenLogin(false);
  };

  const handleWorkspaceUpdate = (newWorkspace: Database) => {
    setTargetWorkspace(newWorkspace);
  };

  const handleTemplateSharing = () => {
    if (targetWorkspace) upsertTemplate();
  };

  const handleWorkspaceDialogClose = (isSharingTriggered: boolean | undefined) => {
    if (isSharingTriggered) handleTemplateSharing();
    setOpenWorkspace(false);
  };

  const onSaveToNovadeLite = () => {
    if (authUser?.user) {
      if (workspaces.length === 1) {
        upsertTemplate();
      } else if (workspaces.length > 1) openWorkspaceDialog();
      else {
        setSnackBarMsg("Sorry you don't have permission to import templates to a workspace. Please contact the admin.");
        setOpenSnackbar(true);
      }
    } else openLoginDialog();
  };

  const handleDownload = () => {
    // no need for email request if user already logged in with NL account
    if (workspaces.length >= 1) {
      exportPDF(authUser?.user!);
    } else {
      setOpenEmail(true);
    }
  };

  const isMobile = matchesMobile();
  const templateInfoProps = {
    isMobile,
    templateTitle: template.Title,
    authorLabel: templatePageContent?.AuthorLabel,
    author: template.Author,
    description: template.Description,
    publishedDateLabel: templatePageContent?.PublishedDateLabel,
    publishedDate: template.published_at,
    tags: templateDetails.tags,
    tagsLabel: templatePageContent?.TagsLabel
  };

  if (templatePageContent) {
    return (
      <TemplateScoreProvider>
        <Seo title={template.Title} description={template.Description} keywords={template.Keywords} isTemplatePage />
        <Container>
          <br />
          <Grid container spacing={6}>
            <Grid item xs={12} md={8}>
              <TemplateInfoSection {...templateInfoProps} />
              <TemplateSection
                templateDetails={templateDetails}
                templateLanguage={templateLanguage}
                templateMaxScoreMap={templateMaxScoreMap}
                templatePageContent={templatePageContent}
              />
            </Grid>
            {!isMobile ? (
              <Grid item xs={12} md={4}>
                <div style={{ position: 'sticky', top: '5.5rem' }}>
                  <ActionButtonsWeb
                    templatePageContent={templatePageContent}
                    exportPDF={handleDownload}
                    onSaveToNL={onSaveToNovadeLite}
                    loading={loading}
                  />
                  <MobileFormContainer
                    templateDetails={templateDetails}
                    templateLanguage={templateLanguage}
                    templateMaxScoreMap={templateMaxScoreMap}
                  />
                </div>
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={1}>
            <TemplateSeoContent isMobile={isMobile} seoContent={template.SeoContent} textColor='#000' />
          </Grid>
        </Container>
        {isMobile ? (
          <ActionButtonsMobile
            templatePageContent={templatePageContent}
            exportPDF={handleDownload}
            onSaveToNL={onSaveToNovadeLite}
            loading={loading}
          />
        ) : null}
        {workspaces.length > 1 ? (
          <WorkspaceDialog
            open={openWorkspace}
            onDialogClose={handleWorkspaceDialogClose}
            workspaces={databasesMap}
            onWorkspaceChange={handleWorkspaceUpdate}
          />
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={openSnackbar}
          onClose={handleSnackbarClose}
          autoHideDuration={5000}
          message={snackBarMsg}
        />
        <LoginDialog open={openLogin} onDialogClose={handleLoginDialogClose} />
        <PardotEmailDialog open={openEmail} templateTitle={template.Title} onDialogClose={handleEmailDialogClose} />
      </TemplateScoreProvider>
    );
  }

  return <Typography variant='body1' />;
};
export default TemplatePage;
