import React from 'react';
import { ListItem, List, ListItemIcon, Typography } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import { TemplateActionButtonsProps } from 'typings';
import useTemplateStyles from '../../styles/templateStyle';

const ActionButtonsWeb: React.FC<TemplateActionButtonsProps> = ({ templatePageContent, exportPDF, onSaveToNL, loading }) => {
  const classes = useTemplateStyles();

  return (
    <List style={{ display: 'flex', justifyContent: 'center' }}>
      <ListItem button onClick={exportPDF} className={classes.buttonList}>
        <ListItemIcon>
          <div className={classes.buttonCircle}>
            <StaticImage
              width={24}
              layout='constrained'
              placeholder='tracedSVG'
              src='../assets/TemplatePreview/pdf.svg'
              alt='Download PDF'
            />
          </div>
        </ListItemIcon>
        <Typography variant='body2' className={classes.listItemText}>
          {templatePageContent.DownloadPDFLabel}
        </Typography>
      </ListItem>
      <ListItem button onClick={onSaveToNL} disabled={loading} className={classes.buttonList}>
        <ListItemIcon>
          <div className={classes.buttonCircle} style={{ backgroundColor: '#2b5b94' }}>
            <StaticImage
              width={24}
              layout='constrained'
              placeholder='tracedSVG'
              src='../assets/TemplatePreview/sync_alt_white.svg'
              alt='Save to Novade Lite'
            />
          </div>
        </ListItemIcon>
        <Typography variant='body2' className={classes.listItemText}>
          {templatePageContent.SaveToNovadeLiteLabel}
        </Typography>
      </ListItem>
    </List>
  );
};

export default ActionButtonsWeb;
